<template>
  <div class="bar">
    <div class="logo">
      <h3>{{title}}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    title:String,
  },
  data() {
    return {

    }
  },
  computed: {
    bgUrl(){

    }
  },
  watch: {},
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>
<style lang='scss' scoped>
.bar {
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4fc08d;

  .logo {
    position: relative;
    width:100%;
    height:100%;
    text-align: center;
    line-height:54px;
    color: white;
  }
}
</style>
