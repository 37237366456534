<template>
  <div  class="body">
     <div>
       <van-form>
         <!-- 允许输入正整数，调起纯数字键盘 -->
         <van-search
             v-model="waterCode"
             shape="round"
             show-action
             background="#4fc08d"
             type="digit"
             @search="onSearch"
             placeholder="请输入缴费户号6位或12位数字"
         >
           <template #action>
             <div @click="onSearch">搜索</div>
           </template>
         </van-search>

         <van-field
             v-model="from.waterCode"
             label="缴费户号:"
             placeholder="缴费户号"
             disabled
         />
         <van-field
             v-model="from.customerName"
             label="缴费户名:"
             placeholder="缴费户名"
             disabled
         />
         <van-field
             v-model="from.customerAddr"
             label="用户地址:"
             placeholder="用户地址"
             disabled
         />
         <van-field
             v-model="from.chargingUnit"
             label="收费单位:"
             placeholder="收费单位"
             disabled
         />
         <van-field
             v-model="from.payAmount"
             label="应缴金额:"
             placeholder="应缴金额"
             disabled
         />
         <van-field
             v-model="from.balance"
             label="结余金额:"
             placeholder="结余金额"
             disabled
         />
       </van-form>
	   <div style="margin: 16px;">
	   	<van-button round block type="primary" @click="back">
	   		返回
	   	</van-button>
	   </div>
     </div>
  </div>
</template>

<script>
import payhead from '../components/payhead'
import request from "@/api/index.js";
export default {
  name: '',
  components: {
    payhead
  },
  props: {},
  data() {
    return {
		waterCode:'',
		from:{
		  waterCode: '',
		  customerName: '',
		  customerAddr: '',
		  chargingUnit: '',
		  payAmount: '',
		  balance: ''
		}
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    
  },
  mounted() {

  },
  methods: {
	onSearch(){
		if(this.waterCode == null || this.waterCode == '' || this.waterCode.length < 6 || this.waterCode.length > 12){
			this.$toast('请输入缴费户号6位或12位数字')
			return;
		}
		request.get('/pay/waterFee/query',{ params: { waterCode: this.waterCode } }).then(res=>{
			if(res.code == 200){
				this.from = res.data
				this.from.payAmount = this.from.payAmount != null ? ((parseFloat(this.from.payAmount)/100).toFixed(2) + ' 元') : '0.00 元'
				this.from.balance = this.from.balance != null ? ((parseFloat(this.from.balance)/100).toFixed(2) + ' 元') : '0.00 元'
			}else{
				this.$toast.fail(res.message)
			}
		}).catch(err=>{
			this.$toast.fail(err.message)
		})
	},
	//关闭整个浏览器
	back() {  
		document.addEventListener('WeixinJSBridgeReady', function() {
			WeixinJSBridge.call('closeWindow');
		}, false);
		WeixinJSBridge.call('closeWindow');
	}
  },
}
</script>
<style lang='scss' scoped>

</style>
